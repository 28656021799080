import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {Table, Modal, Button} from 'antd';
import {ColumnsType} from "antd/es/table";
import {confirmJobData, getJobsPost, rerunJobPost} from "../../redux/report/reportThunk";
import {JobPostStepType, JobPostType} from "./jobPost.type";
import {RoutePath} from "../../constants/constant";
import {toast} from "react-toastify";
import {resetReportDataForTestingPurpose} from "../../redux/report/resetDataThunk";

export const JobPost: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [jobs, setJobs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewJob, setViewJob] = useState<JobPostType>()

  const columns: ColumnsType<JobPostType> = [
    {
      title: 'Job Id',
      dataIndex: '_id',
      key: '_id',
      render: (_, record) => (
        <span>{record._id}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'Job Status',
      key: 'status',
      render: (_, record) => {
        return (<span>{record.jobStatus}</span>)
      },
    },
    {
      title: 'Started',
      dataIndex: 'Created',
      key: 'Created',
      render: (_, record) => (
        <span>{record.createdAt.toString()}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 550,
      render: (_, record) => (
        <div>
          <button
            type="submit"
            onClick={() => navigate(RoutePath.JOBS_POST_VIEW_LOG + record._id)}
            className={
              'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
            }
          >
            View Logs
          </button>
          <button
            type="submit"
            onClick={() => {
              setViewJob(record);
              setIsModalOpen(true)
            }}
            className={
              'py-2 ml-2 px-4 rounded-md bg-black-600 border-2 border-black text-black'
            }
          >
            Raw Data
          </button>
          <button
            type="submit"
            disabled={(record.jobCurrentStep < JobPostStepType.STEP_5)}
            onClick={() => navigate(RoutePath.PREVIEW_ACCOUNT_POSTING + record._id)}
            className={
              record.jobCurrentStep < JobPostStepType.STEP_5? 'py-2 ml-2 px-4 rounded-md bg-gray-600 border-2 border-gray-600 text-white'
                :'py-2 ml-2 px-4 rounded-md bg-blue-500 border-2 border-blue-500 text-white'
            }
          >
            Preview
          </button>
          <button
            type="submit"
            disabled={record.jobIsFinished || record.jobCurrentStep >= JobPostStepType.STEP_6}
            onClick={() => {
              rerunJob(record._id)
            }}
            className={
              record.jobIsFinished || record.jobCurrentStep >= JobPostStepType.STEP_6? 'py-2 ml-2 px-4 rounded-md bg-gray-600 border-2 border-gray-600 text-white'
              :'py-2 ml-2 px-4 rounded-md bg-red-600 border-2 border-red-600 text-white'
            }
          >
            Re-run
          </button>
          <button
            type="submit"
            disabled={!(record.jobCurrentStep === JobPostStepType.STEP_5)}
            onClick={() => {
              confirmJob(record._id);
            }}
            className={
              record.jobCurrentStep !== JobPostStepType.STEP_5? 'py-2 ml-2 px-4 rounded-md bg-gray-600 border-2 border-gray-600 text-white'
              :'py-2 ml-2 px-4 rounded-md bg-green-600 border-2 border-green-600 text-white'
            }
          >
            Confirm
          </button>
        </div>
      ),
    },
  ]

  const getJobsPostData = () => {
    dispatch(getJobsPost())
      .unwrap()
      .then((response) => {
        console.log(response);
        setJobs(response.data.data);
      });
  };
  useEffect(() => {
    void getJobsPostData();
  }, []);

  const confirmJob = (jobId: string) => {
    dispatch(confirmJobData({jobId: jobId}))
      .unwrap()
      .then((response) => {
        toast.success('Confirmed');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  const rerunJob = (jobId: string) => {
    dispatch(rerunJobPost({jobId: jobId}))
      .unwrap()
      .then((response) => {
        toast.success('Job has been reset');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  const resetReportData = () => {
    dispatch(resetReportDataForTestingPurpose())
      .unwrap()
      .then((response) => {
        toast.success('Data has been reset');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }


  const renderJobDataModal = () => {
    return(
      <Modal
        open={isModalOpen}
        title="Data Details"
        onCancel={()=>{setIsModalOpen(false)}}
        footer={[
          <Button key="back" onClick={()=>{setIsModalOpen(false)}}>
            Close
          </Button>
        ]}
      >
        <pre>{(viewJob && viewJob?.playCountData)? JSON.stringify(viewJob.playCountData, null, 2) : ''}</pre>
      </Modal>
    )
  }

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        {process.env.REACT_APP_DEBUG === 'true' && <div className="flex flex-col">
          <button
            type="submit"
            onClick={() => {resetReportData()}}
            className="items-end px-5 py-2 rounded-md border-2 border-red-600 text-red-800"
          >
            <span className="text-red-600">
              Reset Report Data (Just for testing purposes)
            </span>
          </button>
        </div>
        }
      </div>
      <Table columns={columns} dataSource={jobs} />
      {renderJobDataModal()}
    </div>
  );
};
