import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {GET_ALL_PUBLISHER, GET_SONGS_BY_PUBLISHER, RECORD_PLAY_COUNT} from "../types";

export const getMySongs = createAsyncThunk(
  types.GET_SONGS_BY_PUBLISHER,
  async (request  :{publisherId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_SONGS_BY_PUBLISHER + request.publisherId)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const recordPlay = createAsyncThunk(
  types.RECORD_PLAY_COUNT,
  async (request: {
    ip: string,
    countryCode: string,
    trackId: number,
    subscriberId: number,
    duration: number,
    deviceId: string,
    currentPosition: number,
  }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.RECORD_PLAY_COUNT,request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


