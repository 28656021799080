import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {
  GET_JOBS_POST,
  GET_JOBS_POST_LOG, GET_JOBS_POST_LOG_REALTIME,
  GET_MEMBER_COUNT_HISTORY,
  GET_MEMBER_COUNT_DETAIL_HISTORY, GET_SETTING,
  POST_ACTION,
  RECORD_PLAY_COUNT, SAVE_SETING
} from "../types";

export const getSetting = createAsyncThunk(
  types.GET_SETTING,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_SETTING)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const saveSetting = createAsyncThunk(
  types.SAVE_SETING,
  async (request: {config: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.SAVE_SETTING, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);



