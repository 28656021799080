import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { DatePicker,  Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {getMemberCountPending, postingAction} from "../../redux/report/reportThunk";
import {MemberCountType} from "./memberCount.type";
import {RoutePath} from "../../constants/constant";
import { Modal } from 'antd';
import {toast} from "react-toastify";
const { confirm } = Modal;

export const MemberCountsPending: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [memberCount, setMemberCount] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const columns: ColumnsType<MemberCountType> = [
    {
      title: 'Member Name',
      dataIndex: 'publisherId.userName',
      key: 'publisherId.userName',
      render: (_, record) => (
        <span>{record.publisherId.userName}</span>
      ),
    },
    {
      title: 'Member No',
      dataIndex: 'userPublicAddress',
      render: (_, record) => (
        <span>{record.publisherId.userPublicAddress}</span>
      ),
    },
    {
      title: 'Tracks count',
      dataIndex: 'playCounts',
      key: 'playCounts',
    },
    {
      title: '% (Tracks count)',
      key: 'percent',
      dataIndex: 'percent',
      render: (_, record) => (
        <span>{((record.playCounts/totalCount) *100).toFixed(1)}%</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.MEMBER_COUNTS_DETAIL_PENDING + record._id, {
            state:{
              record
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    },
  ]

  const getMemberCountData = () => {
    dispatch(getMemberCountPending())
      .unwrap()
      .then((response) => {
        console.log(response);
        setTotalCount(response.data.totalCount);
        setMemberCount(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountData();
  }, []);

  const handleAccountPosting = () => {
    confirm({
      title: 'Are you sure you want to?',
      content: 'You cannot revert the changes you made to your account',
      onOk() {
        posting();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const posting = () => {
    dispatch(postingAction())
      .unwrap()
      .then((response) => {
        console.log(response);
        toast.info('Request has been processing!!!')
        navigate(RoutePath.JOBS_POST)
      });
  };

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-2/3">
          <div className="flex flex-col">
            <button
              type="submit"
              onClick={() => handleAccountPosting()}
              className="items-end px-5 py-2 rounded-md border-2 border-blue-600 text-blue-800"
            >
            <span className="">
              Accounting Post
            </span>
            </button>
          </div>
        </div>
      </div>
      <Table columns={columns} dataSource={memberCount} />
    </div>
  );
};
