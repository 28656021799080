import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {
  getSongCountDetailHistory,
} from "../../redux/report/reportThunk";
import {SongCountHistoryType} from "./SongCountHistory.type";

export const SongCountsDetailHistory: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [memberCount, setMemberCount] = useState([]);
  const location = useLocation().state;
  if(!location){
    navigate('/')
  }
  let { record } = location;

  if(!record){
    navigate('/')
  }

  const getMemberCountData = () => {
    dispatch(getSongCountDetailHistory({memberCountDetailId: record._id}))
      .unwrap()
      .then((response) => {
        console.log(response);
        setMemberCount(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountData();
  }, [record]);

  const columns: ColumnsType<SongCountHistoryType> = [
    {
      title: 'Subscribed ID',
      dataIndex: 'subscribedId',
      key: 'subscribedId',
      render: (_, record) => (
        <span>{record.subscriberId}</span>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'countryCode',
      key: 'countryCode',
      render: (_, record) => {
          return <span>{record.countryCode}</span>
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'TxId',
      dataIndex: 'TxId',
      key: 'TxId',
      render: (_, record) => (
        <a target="_blank" href={`${process.env.REACT_APP_ALGORAND_EXPOLER_URL}/tx/${record.txid}`}>{record.txid}</a>
      ),
    },
  ]

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2">
          Song Title: {record?.songRefer.songTitle}
          <br/>
          Track ID : {record.trackId}
        </div>
      </div>
      <Table columns={columns} dataSource={memberCount} />
    </div>
  );
};
