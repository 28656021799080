import clsx from 'clsx';
import React, { useState } from 'react';
import Footer from './footer';
import Header from './header';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RoutePath } from '../constants/constant';
export const DefaultLayout: React.FC = () => {
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const location = useLocation();
  const menuList = [
    { page: 'LIVE COUNTS', navigate: '/member-counts-pending' , key: 1},
    { page: 'REPORT MEMBER COUNT', navigate: '/member-counts-history' , key: 1},
    { page: 'ROYALTIES ACCOUNT POSTING', navigate: '/royalty-account-posting', key:2 },
    // { page: 'ACCOUNTING ACCRUAL', navigate: '/accounting-accrual', key: 9 },
    // { page: 'ADVANCE REQUEST', navigate: '/advance-request', key:3 },
    // { page: 'MY LICENSING', navigate: '/my-licensing', key:4 },
    { page: 'MEMBERS', navigate: '/members', key: 11 },
    { page: 'REVENUE SHARE', navigate: '/revenue-share',key: 5 },
    { page: 'SONGS LIBRARY', navigate: '/my-songs', key: 7 },
    { page: 'JOBS POSTING', navigate: '/job-post', key: 8 },
    { page: 'SETTINGS', navigate: '/settings', key: 6 },
    { page: 'LOGOUT', navigate: '/logout', key: 10 },
  ];

  const renderMenuList = () => {
    return (
      <div className="md:w-1/6 flex top-28 flex-col absolute right-3  border-2 rounded-xl bg-white">
        {menuList.map((menu) => {
          return (
            <div key={menu.key} className={'text-blue-500 border-b-2 p-4'}>
              <a className='font-bold text-sm' href={menu.navigate}>{menu.page}</a>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-no-wrap h-screen bg-gray-100 overflow-hidden">
      <div
        className={clsx(
          'main-content flex flex-1 flex-col z-10 bg-gray-100',
          'flex flex-col justify-between',
        )}
      >
        {location.pathname !== RoutePath.LOGIN && (
          <Header
            onToggleMenuMobile={() => setShowMenuMobile(!showMenuMobile)}
          />
        )}
        <div className="overflow-x: hidden;">
          <Outlet />
        </div>
        {location.pathname !== RoutePath.LOGIN && <Footer />}
        {location.pathname !== RoutePath.LOGIN && renderMenuList()}
      </div>
    </div>
  );
};

export default DefaultLayout;
