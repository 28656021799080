export const API = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  PREFIX: 'api/v1/',
  SIGN_IN: 'account/signin',
  CHANGE_PASSWORD: 'account/change-password',
  RESET_PASSWORD: 'account/reset-password',
  FORGOT_PASSWORD: 'account/forgot-password',
  UPDATE_PROFILE: 'account/update-profile',
  BALANCE: 'account/balance',
  SIGN_UP: 'account/signup',
  LOGOUT: 'logout',
  GET_DATA_SIGN_UP: 'account/new',
  ADMIN_SIGN_IN: 'admin/signin',

  GET_USER_AUTO_COMPLETED: 'account/autoComplete',
  GET_SONGS_BY_PUBLISHER: 'admin/songs/',

  //member count
  RECORD_PLAY_COUNT: 'device/play-report',
  GET_MEMBER_COUNT_HISTORY: '/admin/report/member-counts-history',
  GET_MEMBER_COUNT_DETAIL_HISTORY: '/admin/report/member-counts-history-detail',
  GET_SONG_COUNT_DETAIL_HISTORY: '/admin/report/song-counts-detail-history',

  GET_MEMBER_COUNT_PENDING: '/admin/report/member-counts-pending',
  GET_MEMBER_COUNT_DETAIL_PENDING: '/admin/report/member-counts-detail-pending',
  GET_SONG_COUNT_DETAIL_PENDING: '/admin/report/song-counts-detail-pending',

  GET_ACCRUAL_REPORT: '/admin/report/accrual-report',
  GET_ACCRUAL_MEMBER_REPORT: '/admin/report/accrual-member-report',

  GET_JOBS_POST: 'admin/report/jobs',
  GET_JOBS_POST_LOG: 'admin/report/job-detail',
  GET_JOBS_POST_LOG_REALTIME: 'admin/report/log-realtime',
  CONFIRM_JOBS_POST: 'admin/report/job/confirm/',
  RE_RUN_JOB_POST: 'admin/report/job/re-run/',
  POST_ACTION: 'admin/report/post-action',

  CREATE_CMO_LICENSE: 'cmo/licensing/create',
  CREATE_OR_UPDATE_REVENUE_SHARE: 'admin/revenue-share/createOrUpdate',
  GET_ALL_REVENUE_SHARE: 'admin/revenue-share',

  //system settings
  SAVE_SETTING: 'setting/save',
  GET_SETTING: 'setting/get',

  //member management
  GET_MEMBERS: 'admin/member/list',


  //reset report data -> testing purposes
  RESET_REPORT_DATA: '/admin/report/reset-data',
};
