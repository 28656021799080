import React, { useEffect } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { getBalance } from '../../redux/home/homeThunk';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';

export const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getBalance())
      .unwrap()
      .then((response) => {});
  }, []);


  return (
    <div className="w-full overflow-hidden">
      <div className="col-span-12 lg:col-span-3 h-full items-center flex mt-20 lg:mt-0 ">
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-row">
            {/*<BarChart*/}
            {/*  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}*/}
            {/*  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}*/}
            {/*  width={500}*/}
            {/*  height={300}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
