import React, {useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {Input, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {MemberType} from "./member.type";
import {getMembers} from "../../redux/member/memberThunk";
const { Search } = Input;


export const Members: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [membersData, setMemberData] = useState([])
  const getAllMembers = (keyword: string) => {
    console.log(keyword)
    dispatch(getMembers({keyword}))
      .unwrap()
      .then((response) => {
        setMemberData(response.data.data);
      });
  };

  useEffect(() => {
    void getAllMembers('');
  }, [dispatch]);

  const columns: ColumnsType<MemberType> = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'userEmail',
      key: 'userEmail',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Account Number',
      dataIndex: 'userPublicAddress',
      key: 'userPublicAddress',
      render: (text) => <a>{text}</a>,
    },
  ];

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 ml-0">
          <Search placeholder="keyword" onSearch={getAllMembers} size="large" />
        </div>
      </div>
      <Table columns={columns} dataSource={membersData} />
    </div>
  );
};
