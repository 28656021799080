import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {AutoComplete, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {getUserAutoComplete} from "../../redux/home/homeThunk";
import {getMySongs, recordPlay} from "../../redux/song/songThunk";
import {countryCode, SongType} from "./song.type";
import {toast} from "react-toastify";
import clsx from "clsx";
import {autoCompleteUser} from "../revenue_share/revenue.type";
import { DownOutlined } from '@ant-design/icons';

export const MySongs: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mysongs, setMySongs] = useState([])
  const [url, setUrl] = useState('')
  const [options, setOptions] = useState<autoCompleteUser[]>([]);

  const getSongByPublisherData = (publisherId: string) => {
    dispatch(getMySongs({publisherId}))
      .unwrap()
      .then((response) => {
        setMySongs(response.data.data);
        setUrl(response.data.url);
      });
  };

  const handleSearchUser = (value: string) => {
    dispatch(getUserAutoComplete({keyword: value}))
      .unwrap()
      .then((response) => {
        setOptions(response.data.data)
      });
  };

  const onPlayRecord = (record: SongType) => {
    const randomNumber = Math.floor(Math.random() * countryCode.length) + 1;
    const data = {
      ip: '192.168.1.' + randomNumber.toString(),
      countryCode: countryCode[randomNumber],
      trackId: record.trackId,
      subscriberId: randomNumber,
      duration: randomNumber * 1000,
      deviceId: 'id' + (randomNumber * 989).toString(),
      currentPosition: 0,
    }
    dispatch(recordPlay(data))
      .unwrap()
      .then((response) => {
        console.log(response);
        toast.info('done');
      });
  }

  const columns: ColumnsType<SongType> = [
    {
      title: 'Track Id',
      dataIndex: 'trackId',
      key: 'trackId',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Artist Name',
      dataIndex: 'artist',
      key: 'artist',
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: 'Time',
    //   dataIndex: 'time',
    //   key: 'time',
    //   render: (text) => <span>{text}</span>,
    // },
    // {
    //   title: 'Register Address',
    //   key: 'register_address',
    //   dataIndex: 'register_address',
    //   render: (text) => <a>{text}</a>,
    // },
    // {
    //   title: 'Verification',
    //   key: 'verified',
    //   dataIndex: 'verified',
    //   render: (_, record) => <span className={'text-blue-700 font-bold'}>{record.verified? 'VERIFIED':'NOT VERIFIED'}</span>,
    // },
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (_, record) => (
          <div className={'flex flex-row items-center'}>
            <a
                type="submit"
                href={url + record.trackId.toString()}
                target="_blank"
                className={
                  'py-2 px-4 mr-3 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
                }
            >
              Detail
            </a>
            <a
              onClick={()=> {onPlayRecord(record)}}
              type="submit"
              href='#'
              className={
                'py-2 px-4 mr-3 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
              }
            >
              Play
            </a>
          </div>
      ),
    },
  ];

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 ml-0">
          <label>Select Member: </label>
          <AutoComplete
            id="publisher_user_name"
            bordered={false}
            style={{width: '100%', height: '', padding: 8}}
            onSearch={handleSearchUser}
            placeholder="Publisher Name"
            options={options}
            autoFocus
            onSelect={(item: any, option) =>{
              if (!option) {
                return;
              }
              let {id} = option;
              getSongByPublisherData(id);
            }}
            className={clsx(
              "outline-none px-5 rounded-md border border-black placeholder-white-400 text-white-600"
            )}
          />
          <DownOutlined className="relative right-8 top-3 text-xl"/>
        </div>
      </div>
      <Table columns={columns} dataSource={mysongs} />
    </div>
  );
};
