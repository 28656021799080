import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {Button, Modal, Select, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {useFormik} from 'formik';
import { AutoComplete } from 'antd';
import {toast} from "react-toastify";
import clsx from "clsx";
import {getUserAutoComplete} from "../../redux/home/homeThunk";
import {
  autoCompleteUser,
  revenueSchema,
  RevenueShareDataType,
  RevenueShareResponseType
} from "./revenue.type";
import {createOrUpdateRevenueShare, getAllRevenueShare} from "../../redux/revenue/revenueThunk";
const countryCodes = require('country-codes-list')

export const RevenueShare: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revenueShare, setRevenueShare] = useState();
  const [referUrl, setReferUrl] = useState('');
  const [options, setOptions] = useState<autoCompleteUser[]>([]);
  const [countries, setCountries] = useState<{value: string; label: string}[]>([]);
  const [editedObject, setEditedObject] = useState<RevenueShareResponseType>();
  const [editMode, setEditMode] = useState<boolean>();
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn}');
  const handleSearchUser = (value: string) => {
    dispatch(getUserAutoComplete({keyword: value}))
      .unwrap()
      .then((response) => {
        setOptions(response.data.data)
      });
  };

  const getAllRevenueShareData = () => {
    dispatch(getAllRevenueShare())
      .unwrap()
      .then((response) => {
        setRevenueShare(response.data.data);
        console.log(response.data.referUrl);
        setReferUrl(response.data.referUrl);
      });
  };

  const getCountries = () => {
    const data = Object.entries(myCountryCodesObject).map(entry => {
      return {
        value: entry[0].toLowerCase(),
        label: entry[1],
      }
    });
    // @ts-ignore
    setCountries(data);
  }

  useEffect(() => {
    void getAllRevenueShareData();
    void getCountries();
  }, [dispatch]);

  const validateForm = () => {
    if(formik.values.cmo_user_name){
      if(!formik.values.cmo_id){
        formik.setErrors({cmo_user_name:"User not found"})
        return false
      }
      if(formik.values.cmo_fee <= 0){
        formik.setErrors({cmo_fee:"invalid cmo fee"})
        return false;
      }
    }
    const totalPercent = Number(formik.values.cmo_fee) + Number(formik.values.licensee_fee) + Number(formik.values.publisher_fee) + Number(formik.values.system_fee);
    if(totalPercent != 100){
      if(!formik.values.cmo_id) {
        formik.setErrors({cmo_fee: "invalid cmo fee"})
      }
      formik.setErrors({licensee_fee:"invalid licensee fee"})
      formik.setErrors({publisher_fee:"invalid member fee"})
      formik.setErrors({system_fee:"invalid system fee"})
      return false;
    }
    return true;
  }

  const submitForm = (values: RevenueShareDataType, actions: any) => {
    try{
      if(!validateForm()){
        setLoading(false);
        toast.error('Invalid Data')
        return;
      }
      setLoading(true);
      dispatch(
        createOrUpdateRevenueShare(formik.values),
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          setIsModalOpen(false);
          toast.info(response.data.message);
        })
    }catch (ex){
      console.log(ex)
    }finally {
      setLoading(false);
    }
  };

  const onViewRevenueShare = (record: RevenueShareResponseType) => {
    setEditedObject(record);
    setIsModalOpen(true);
  }

  const columns: ColumnsType<RevenueShareResponseType> = [
    {
      title: 'Licensee',
      dataIndex: 'license_account',
      key: 'license_account',
      render: (_, record) => (
        <span>{record.licenseeId?.userName}</span>
      ),
    },
    {
      title: 'Percentage',
      dataIndex: 'publisher_account',
      key: 'publisher_account',
      render: (_, record) => (
        <span>{record.licenseeFee}%</span>
      ),
    },
    {
      title: 'Territory code',
      dataIndex: 'territory',
      key: 'territory',
      render: (_, record) => {
        const countryCode = record.countryCode.split(',');
        const countries: string[] = [];
        countryCode.forEach(item => {
          const value = myCountryCodesObject[item.toUpperCase()];
          if(value) countries.push(value)
        })
        return <span>{countries.join(',')}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'publisher_account',
      key: 'publisher_account',
      render: (_, record) => (
        <span>Confirmed</span>
      ),
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (_, record) => (
        <span>{record.createdAt.toString()}</span>
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div className={'flex flex-row items-center'}>
          <button
            type="submit"
            className={clsx(
              "py-2 px-4 mr-3 rounded-md border-2 text-white",
              (record.needToBeConfirm && record.userConfirmed !=null)? (record.userConfirmed? "bg-green-600" :"bg-red-600") :"bg-blue-600"
            )}
            onClick={() => onViewRevenueShare(record)}
          >
            {(record.needToBeConfirm && record.userConfirmed != null)? (record.userConfirmed? 'View': 'Declined') :'View'}
          </button>
        </div>
      ),
    },
  ];

  const formik = useFormik<RevenueShareDataType>({
    initialValues: {
      id: editedObject?._id,
      licensee_email: editedObject?.licenseeId?.userEmail || '',
      licensee_user_name: editedObject?.licenseeId?.userName || '',
      licensee_id: editedObject?.licenseeId?._id || '',
      licensee_fee: editedObject?.licenseeFee || 0,
      system_fee: editedObject?.systemFee || 0,
      country_code: editedObject?.countryCode || '',
      cmo_fee: editedObject?.cmoFee || 0,
      cmo_email: editedObject?.cmoId?.userEmail || '',
      cmo_id: editedObject?.cmoId?._id || '',
      cmo_user_name: editedObject?.cmoId?.userName || '',
      publisher_fee: editedObject?.publisherFee || 0,
      publisher_email: editedObject?.publisherId?.userEmail || '',
      publisher_id: editedObject?.publisherId?._id || '',
      publisher_user_name: editedObject?.publisherId?.userName || '',
      need_to_be_confirm: editedObject?.needToBeConfirm || false,
    },
    enableReinitialize: true,
    validationSchema: revenueSchema,
    onSubmit: submitForm,
  });

  const footer = () => {
    let buttons = [];
    if(editedObject){
      buttons.push(
        <Button style={{float: 'left'}} key="back" onClick={()=>{setIsModalOpen(loading)}}>
          <a href={referUrl + editedObject?.appId} target="#">View Smart Contract</a>
        </Button>
      )
    }
    buttons.push(
      <Button key="back" disabled={loading} onClick={()=>{
        setEditMode(false);
        setIsModalOpen(loading)
      }}>
        Cancel
      </Button>
    )
    if(editedObject && !editMode && !editedObject.needToBeConfirm){
      buttons.push(
        <Button
          key="link"
          href="#"
          type="primary"
          loading={loading}
          onClick={()=> {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      )
      return buttons;
    }
    if(editedObject && !editedObject.needToBeConfirm) {
      buttons.push(
        <Button
          key="link"
          href="#"
          type="primary"
          loading={loading}
          onClick={() => {
            if (!validateForm()) {
              setLoading(false);
              toast.error('Invalid Data')
              return;
            }
            setLoading(true);
            formik.submitForm().then();
          }}
        >
          {loading ? "Processing" : (editedObject ? "Update" : "Submit")}
        </Button>
      )
    }
    return buttons;
  }


  const renderAddNewLicensingModal = () => {
    return (
      <Modal
        width={600}
        title="Add New Revenue Sharing Smart Contract"
        open={isModalOpen}
        onCancel={()=>{setIsModalOpen(false)}}
        footer={footer()}
      >
        <form>
          <div className="mt-5">
            <div className={'flex flex-row justify-between'}>
              <div style={{width:'50%', marginRight:5}}>
                <label className="block font-bold">Licensee account</label>
                <AutoComplete
                  id="licensee_user_name"
                  bordered={false}
                  style={{width: '100%', height: '', padding: 8}}
                  onSearch={handleSearchUser}
                  placeholder="licensee_user_name"
                  options={options}
                  autoFocus
                  disabled={!!editedObject}
                  value={formik.values.licensee_user_name}
                  onBlur={formik.handleBlur('licensee_user_name')}
                  onChange={
                    formik.handleChange('licensee_user_name')
                  }
                  onSelect={(item: any, option) =>{
                    if (!option) {
                      return;
                    }
                    let {id, userEmail, userName} = option;
                    formik.setFieldValue('licensee_user_name', userName);
                    formik.setFieldValue('licensee_id', id);
                    formik.setFieldValue('licensee_email', userEmail);
                  }}
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_user_name? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'50%',marginRight:5, marginTop:2}}>
                <input
                  name="licensee_email"
                  id="licensee_email"
                  autoFocus
                  disabled={true}
                  placeholder="email"
                  value={formik.values.licensee_email}
                  onBlur={formik.handleBlur('licensee_email')}
                  onChange={formik.handleChange('licensee_email')}
                  className={clsx(
                    "outline-none w-full mt-5 px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_email? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'20%'}}>
                <label className="block font-bold">Percentage(%)</label>
                <input
                  name="licensee_fee"
                  id="licensee_fee"
                  autoFocus
                  disabled={editedObject && !editMode}
                  placeholder="10"
                  value={formik.values.licensee_fee}
                  onBlur={formik.handleBlur('licensee_fee')}
                  onChange={formik.handleChange('licensee_fee')}
                  className={clsx(
                    "outline-none w-full px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_fee? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
            </div>
            {/*<div className={'flex flex-row justify-between mt-5'}>*/}
            {/*  <div style={{width:'50%', marginRight:5}}>*/}
            {/*    <label className="block font-bold">Licensor</label>*/}
            {/*    <AutoComplete*/}
            {/*      id="publisher_user_name"*/}
            {/*      bordered={false}*/}
            {/*      style={{width: '100%', height: '', padding: 8}}*/}
            {/*      onSearch={handleSearchUser}*/}
            {/*      placeholder="Publisher Name"*/}
            {/*      options={options}*/}
            {/*      disabled={!!editedObject}*/}
            {/*      autoFocus*/}
            {/*      value={formik.values.publisher_user_name}*/}
            {/*      onBlur={formik.handleBlur('publisher_user_name')}*/}
            {/*      onChange={*/}
            {/*        formik.handleChange('publisher_user_name')*/}
            {/*      }*/}
            {/*      onSelect={(item: any, option) =>{*/}
            {/*        if (!option) {*/}
            {/*          return;*/}
            {/*        }*/}
            {/*        let {id, userEmail, userName} = option;*/}
            {/*        formik.setFieldValue('publisher_user_name', userName);*/}
            {/*        formik.setFieldValue('publisher_id', id);*/}
            {/*        formik.setFieldValue('publisher_email', userEmail);*/}
            {/*      }}*/}
            {/*      className={clsx(*/}
            {/*        "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",*/}
            {/*        formik.errors.publisher_user_name? "border-red-400": "border-gray-200"*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div style={{width:'50%',marginRight:5, marginTop:2}}>*/}
            {/*    <input*/}
            {/*      name="publisher_email"*/}
            {/*      id="publisher_email"*/}
            {/*      autoFocus*/}
            {/*      disabled={true}*/}
            {/*      placeholder="email"*/}
            {/*      value={formik.values.publisher_email}*/}
            {/*      onBlur={formik.handleBlur('publisher_email')}*/}
            {/*      onChange={formik.handleChange('publisher_email')}*/}
            {/*      className={clsx(*/}
            {/*        "outline-none w-full mt-5 px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",*/}
            {/*        formik.errors.publisher_email? "border-red-400": "border-gray-200"*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div style={{width:'20%'}}>*/}
            {/*    <label className="block font-bold">Percentage(%)</label>*/}
            {/*    <input*/}
            {/*      name="publisher_fee"*/}
            {/*      id="publisher_fee"*/}
            {/*      autoFocus*/}
            {/*      disabled={editedObject && !editMode}*/}
            {/*      placeholder="10"*/}
            {/*      value={formik.values.publisher_fee}*/}
            {/*      onBlur={formik.handleBlur('publisher_fee')}*/}
            {/*      onChange={formik.handleChange('publisher_fee')}*/}
            {/*      className={clsx(*/}
            {/*        "outline-none w-full px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",*/}
            {/*        formik.errors.publisher_fee? "border-red-400": "border-gray-200"*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={'flex flex-row justify-between mt-5'}>
              <div style={{width:'60%', marginRight:5}}>
                <label className="block font-bold" htmlFor="password">
                  Territories
                </label>
                {/*<CountryDropdown*/}
                {/*  classes={clsx(*/}
                {/*    "outline-none w-full px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",*/}
                {/*    formik.errors.country_code? "border-red-400": "border-gray-200"*/}
                {/*  )}*/}
                {/*  value={formik.values.country_code}*/}
                {/*  showDefaultOption={true}*/}
                {/*  valueType={"short"}*/}
                {/*  defaultOptionLabel={formik.values.country_code || 'Select a Country'}*/}
                {/*  onChange={(val) => formik.setFieldValue('country_code', val)}*/}
                {/*/>*/}
                <Select
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.country_code? "border-red-400": "border-gray-200"
                  )}
                  key="value"
                  mode="multiple"
                  placeholder="Please select"
                  value={ formik.values.country_code.length > 0? formik.values.country_code.split(','): null}
                  onChange={(val) => formik.setFieldValue('country_code', val.join(','))}
                  options={countries}
                  style={{width: '100%', height: '', padding: 7}}
                  bordered={false}
                />
              </div>
              {/*<div style={{width:'30%'}}>*/}
              {/*  <label className="block font-bold">System Fee(%)</label>*/}
              {/*  <input*/}
              {/*    name="system_fee"*/}
              {/*    id="system_fee"*/}
              {/*    autoFocus*/}
              {/*    disabled={editedObject && !editMode}*/}
              {/*    placeholder="10"*/}
              {/*    value={formik.values.system_fee}*/}
              {/*    onBlur={formik.handleBlur('system_fee')}*/}
              {/*    onChange={formik.handleChange('system_fee')}*/}
              {/*    className={clsx(*/}
              {/*      "outline-none w-full px-5 py-3 mt-1 rounded-md border placeholder-gray-400 text-gray-600",*/}
              {/*      formik.errors.system_fee? "border-red-400": "border-gray-200"*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            {/*<div className={'flex flex-row mt-5'}>*/}
            {/*  <label className="block font-bold mr-2">Need to be confirmed  </label>*/}
            {/*  <input*/}
            {/*    type="checkbox"*/}
            {/*    disabled={editedObject && !editMode}*/}
            {/*    className={clsx("pl-5")}*/}
            {/*    name="need_to_be_confirm"*/}
            {/*    checked={formik.values.need_to_be_confirm}*/}
            {/*    onChange={formik.handleChange('need_to_be_confirm')}*/}
            {/*    id="need_to_be_confirm"/>*/}
            {/*</div>*/}
          </div>
          </form>
      </Modal>
    );
  };

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 ml-10 hidden">
          <button
            type="submit"
            onClick={() => {
              setEditedObject(undefined);
              setIsModalOpen(true);
            }}
            className={'p-2 rounded-md border-2 border-blue-600 text-blue-800'}
          >
            New Revenue Sharing Smart Contract
          </button>
        </div>
      </div>
      <Table rowKey="_id" columns={columns} dataSource={revenueShare}  scroll={{ x: '100%', y: 800 }}/>
      {renderAddNewLicensingModal()}
    </div>
  );
};
