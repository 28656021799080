import * as Yup from 'yup';

export interface SettingType {
  config: string;
}

export const settingSchema = Yup.object().shape({
  config: Yup.string().required('config required'),
});

export const initialValues: SettingType = {
  config: '',
};
