import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {useNavigate, useParams} from 'react-router-dom';
import {DatePicker, Space, Table, Tag} from 'antd';
import {ColumnsType} from "antd/es/table";
import {getJobsPostDetail, getPostLogRealtime} from "../../redux/report/reportThunk";
import {JobPostType} from "../job_posts/jobPost.type";

interface DataType {
  key?: number;
  user: any;
  amount: number;
  playCount: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Account Name',
    dataIndex: 'user.userName',
    key: 'user.userName',
    render: (_, record) => (
      <span>{record.user.userName}</span>
    ),
  },
  {
    title: 'Counts',
    dataIndex: 'playCount',
    key: 'playCount',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'EBTA Accrual',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => <a>{text}</a>,
  },
]


export const PreviewRoyaltyAccountPosting: React.FC = () => {
  const dispatch = useAppDispatch();
  const [jobData, setJobData] = useState<JobPostType>()
  let { jobId } = useParams();

  useEffect(() => {
    void getJobsPostData();
  }, [jobId]);

  const getJobsPostData = () => {
    if(!jobId) return;
    dispatch(getJobsPostDetail({jobId}))
      .unwrap()
      .then((response) => {
        setJobData(response.data.data);
      });
  };

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2">
          Preview Accounting post
        </div>
      </div>
      <Table columns={columns} dataSource={jobData?.payOutData.payTo} />
    </div>
  );
};
