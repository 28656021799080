import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';

export const getMembers = createAsyncThunk(
    types.GET_MEMBERS,
    async ( request: {keyword: string}) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .get(API.GET_MEMBERS + '?keyword=' + request.keyword)
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);