//SMS login
export const INIT_DATA = 'INIT_DATA';
export const BALANCE = 'BALANCE';
export const SIGN_IN = 'SIGN_IN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGN_UP = 'SIGN_UP';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOGOUT = 'LOGOUT';
export const CREATE_OR_UPDATE_LICENSE = 'CREATE_OR_UPDATE_LICENSE';

export const USER_AUTO_COMPLETE = 'USER_AUTO_COMPLETE';
export const GET_SONGS_BY_PUBLISHER = 'GET_SONGS_BY_PUBLISHER';
export const RECORD_PLAY_COUNT = 'RECORD_PLAY_COUNT';
export const GET_MEMBER_COUNT_HISTORY = 'GET_MEMBER_COUNT_HISTORY';
export const GET_MEMBER_COUNT_DETAIL_HISTORY = 'GET_MEMBER_COUNT_DETAIL_HISTORY';
export const GET_SONG_COUNT_DETAIL_HISTORY = 'GET_SONG_COUNT_DETAIL_HISTORY';
export const GET_JOBS_POST = 'GET_JOBS_POST';
export const GET_JOBS_POST_LOG = 'GET_JOBS_POST_LOG';
export const GET_JOBS_POST_LOG_REALTIME = 'GET_JOBS_POST_LOG_REALTIME';
export const CONFIRM_JOB_POST = 'CONFIRM_JOB_POST';
export const RE_RUN_JOB_POST = 'RE_RUN_JOB_POST';
export const POST_ACTION = 'POST_ACTION';
export const SAVE_SETING = 'SAVE_SETTING';
export const GET_SETTING = 'GET_SETTING';
export const CREATE_OR_UPDATE_REVENUE_SHARE = 'CREATE_OR_UPDATE_REVENUE_SHARE';
export const GET_ALL_REVENUE_SHARE = 'GET_ALL_REVENUE_SHARE';

export const GET_ALL_PUBLISHER = 'GET_ALL_PUBLISHER';

export const GET_MEMBER_COUNT_PENDING = 'GET_MEMBER_COUNT_PENDING';
export const GET_MEMBER_COUNT_DETAIL_PENDING = 'GET_MEMBER_COUNT_DETAIL_PENDING';
export const GET_SONG_COUNT_DETAIL_PENDING = 'GET_SONG_COUNT_DETAIL_PENDING';
export const GET_ACCRUAL_REPORT = 'GET_ACCRUAL_REPORT';
export const GET_ACCRUAL_MEMBER_REPORT = 'GET_ACCRUAL_MEMBER_REPORT';
export const GET_MEMBERS = 'GET_MEMBERS';

export const RESET_REPORT_DATA = 'RESET_REPORT_DATA'; //TESTING PURPOSE


