import React from "react";

interface Props{
    label: string;
    value: string;
}
export const ItemProfile=(props: Props)=>{
    const {label, value} = props;
    return <div className="flex-row items-center">
        <span className="text-center mr-10 font-bold">{label}</span>
        <span className="text-center">{value}</span>
    </div>
}
