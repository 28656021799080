import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import {useNavigate, useParams} from 'react-router-dom';
import {getJobsPostDetail, getPostLogRealtime} from "../../redux/report/reportThunk";
import {JobPostType} from "../job_posts/jobPost.type";
import { LogViewer } from '@patternfly/react-log-viewer';
import { Checkbox } from '@patternfly/react-core';

export const JobPostViewLog: React.FC = () => {
  const dispatch = useAppDispatch();
  const [jobData, setJobData] = useState<JobPostType>()
  const [logs, setlogs] = useState('');
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);

  let { jobId } = useParams();
  const getJobsPostData = () => {
    if(!jobId) return;
    dispatch(getJobsPostDetail({jobId}))
      .unwrap()
      .then((response) => {
        console.log(response);
        setJobData(response.data.data);
      });
  };

  useEffect(() => {
    void getJobsPostData();
    const fetchlogs = async () => {
      if(!jobId) return;
      dispatch(getPostLogRealtime({jobId}))
        .unwrap()
        .then((response) => {
          console.log(response);
          setlogs(response.data.data);
        });
    }
    // setup interval to fetch logs
    const intervaltimer = setInterval(fetchlogs, 1000);
    // function to clean up effect when component unmounts
    return () => clearInterval(intervaltimer);
  }, [jobId]);


  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-col my-10 lg:mt-0">
        <label>JobId: {jobData?._id}</label>
        <label>Status: {jobData?.jobIsFinished? 'Completed' : 'Pending'}</label>
        <Checkbox
          label="Dark theme"
          isChecked={isDarkTheme}
          aria-label="toggle dark theme checkbox"
          id="toggle-dark-theme"
          name="toggle-dark-theme"
        />
        <LogViewer hasLineNumbers={false} height={700} data={logs} theme={isDarkTheme ? 'dark' : 'light'} />
      </div>
    </div>
  );
};
