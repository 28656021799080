import * as Yup from 'yup';
import {MenuItemType} from "antd/es/menu/hooks/useItems";
export interface SongType {
  trackId: number;
  songTitle: string;
  createdAt: string;
}

export const countryCode = [
  'sg',
  'vn',
  'my',
  'cn',
  'us',
  'fr',
]