import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {
  getMemberCountDetailPending,
} from "../../redux/report/reportThunk";
import {MemberCountType} from "./memberCount.type";
import {RoutePath} from "../../constants/constant";

export const MemberCountsDetailPending: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [memberCount, setMemberCount] = useState([]);
  let { publisherId } = useParams();
  const location = useLocation().state;
  if(!location){
    navigate('/')
  }
  let { record } = location;

  if(!record){
    navigate('/')
  }

  const getMemberCountData = () => {
    if(!publisherId) return;
    dispatch(getMemberCountDetailPending({publisherId: publisherId}))
      .unwrap()
      .then((response) => {
        console.log(response);
        setMemberCount(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountData();
  }, [publisherId]);

  const columns: ColumnsType<MemberCountType> = [
    {
      title: 'TrackId',
      dataIndex: 'trackId',
      key: 'TrackId',
      render: (_, record) => (
        <span>{record.trackId}</span>
      ),
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      render: (_, record) => {
        if(!record.track) return null;
        // @ts-ignore
        const track = record.track[0];
        if (!track) {
          return <span></span>
        } else {
          return <span>{track?.songTitle}</span>
        }
      },
    },
    {
      title: 'Tracks count',
      dataIndex: 'playCount',
      key: 'playCount',
      width: 150,
      className: 'text-center',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.SONG_COUNTS_DETAIL_PENDING, {
            state:{
              record: record
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    },
  ]

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2">
          Account Name: {record.publisherId.userName}
          <br/>
          Account No: {record.publisherId.userPublicAddress}
        </div>
      </div>
      <Table columns={columns} dataSource={memberCount} />
    </div>
  );
};
