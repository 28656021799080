import React from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { ItemProfile } from './components/ItemProfile';

export const Profile: React.FC = () => {
  return (
    <div className="w-full overflow-hidden">
              <span className="uppercase text-2xl font-semibold mt-10 mx-4">
                My Account
              </span>
        <div className="mx-auto p-10">
              <ItemProfile label='User ID' value={'vinhdo@gmail.com'}/>
              <ItemProfile label='Public wallet' value={'4734Y6O4ZG4GLZLKGCWSB5KPYGBN53TRY7NPKF3EPV7DOK72ICFIRIFPQU\n'}/>
              <ItemProfile label='Email' value={'vinhdo@gmail.com'}/>
              <ItemProfile label='Address' value={''}/>
              <ItemProfile label='City' value={''}/>
              <ItemProfile label='Postal' value={''}/>
              <ItemProfile label='Country' value={''}/>
              <ItemProfile label='Mobile' value={''}/>
          </div>
    </div>
  );
};
