import { Button, Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
interface Props {
  items: MenuItemType[];
  onClick: MenuProps['onClick'];
  label: string;
  labelDropdown: ReactNode;
}
export const CustomDropdown = (props: Props) => {
  const { items, onClick, label, labelDropdown } = props;
  return (
    <div className="">
      <label className="block mb-1 font-bold">{label}</label>
      <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <Button>
          <Space>
            {labelDropdown}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};
