import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';

export const createOrUpdateLicensing = createAsyncThunk(
  types.CREATE_OR_UPDATE_LICENSE,
  async (request: { licenseeId: string; cmoId: string, percent: number, currency_unit: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.SIGN_IN, {
        licenseeId: request.licenseeId,
        cmoId: request.cmoId,
        percent: request.percent,
        currency: request.currency_unit,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


