import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {DatePicker, Space, Table, Tag} from 'antd';
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";
import {RoyaltyType} from "./royalty.type";
import {getAccuralReport, getMemberCountHistory} from "../../redux/report/reportThunk";
import {DATE_FORMAT, RoutePath} from "../../constants/constant";
import {useNavigate} from "react-router-dom";
const { RangePicker } = DatePicker;

export const RoyaltyAccountPosting: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [royaltyAccountData, setRoyaltyAccountData] = useState([]);

  const getAccrualReportData = () => {
    dispatch(getAccuralReport({startDate: startDate.format(DATE_FORMAT), endDate: endDate.format(DATE_FORMAT)}))
      .unwrap()
      .then((response) => {
        setRoyaltyAccountData(response.data.data);
      });
  };
  useEffect(() => {
    void getAccrualReportData();
  }, []);

  const columns: ColumnsType<RoyaltyType> = [
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span>{record.userRefer?.userName}</span>
      ),
    },
    {
      title: 'Counts',
      dataIndex: 'count',
      key: 'count',
      render: (_, record) => (
        <span>{record.totalPlayCount}</span>
      ),
    },
    {
      title: 'EBTA Accrual',
      dataIndex: 'accrual',
      key: 'accrual',
      render: (_, record) => (
        <span>{(record.totalAmount/1000).toFixed(2)}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.ACCRUAL_MEMBER_DETAIL, {
            state:{
              publisher: record
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    },
  ]

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 hidden">
          <RangePicker picker="month" value={[startDate, endDate]} onCalendarChange={(val) => {
            if(val && val.length > 1) {
              if(val[0]){
                setStartDate(val[0])
              }
              if(val[1]){
                setEndDate(val[1])
              }
            }
          }}/>
          <button
            type="submit"
            // onClick={() => downloadKeystore()}
            className={
              'p-2 rounded-md border-2 border-blue-600 text-blue-800 ml-10'
            }
          >
            Get Report
          </button>
        </div>
        <div className="flex flex-row w-1/2">Accrual Summary</div>
      </div>
      <Table columns={columns} dataSource={royaltyAccountData} />
    </div>
  );
};
