import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';

export const initData = createAsyncThunk(
    types.INIT_DATA,
    async () => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .get(API.GET_DATA_SIGN_UP)
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const signIn = createAsyncThunk(
  types.SIGN_IN,
  async (request: { username: string; password: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.ADMIN_SIGN_IN, {
        username: request.username,
        password: request.password,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const forgotPassword = createAsyncThunk(
    types.FORGOT_PASSWORD,
    async (request: { email: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.FORGOT_PASSWORD, {
                email: request.email,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);
export const resetPassword = createAsyncThunk(
    types.RESET_PASSWORD,
    async (request: { password: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.RESET_PASSWORD, {
                email: request.password,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const changePassword = createAsyncThunk(
  types.CHANGE_PASSWORD,
  async (request: {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
  }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.CHANGE_PASSWORD, {
        old_password: request.old_password,
        new_password: request.new_password,
        confirm_new_password: request.confirm_new_password,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const logoutUser = createAsyncThunk(types.LOGOUT, async () => {
  store.dispatch(appActions.showLoading());
  return axiosClient
    .get(API.LOGOUT)
    .then((response) => {
      store.dispatch(appActions.hideLoading());
      return response;
    })
    .catch((err) => {
      store.dispatch(appActions.hideLoading());
      throw err;
    });
});
