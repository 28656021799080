import * as Yup from 'yup';

export interface RevenueShareDataType {
  key?: number;
  id?: string;
  licensee_email: string;
  licensee_user_name: string;
  licensee_id: string;
  licensee_fee: number;
  system_fee: number;
  country_code:string;
  cmo_fee: number;
  cmo_email: string;
  cmo_id: string;
  cmo_user_name: string;
  publisher_fee: number;
  publisher_email: string;
  publisher_id: string;
  publisher_user_name: string;
  need_to_be_confirm: boolean;
}
export const revenueSchema = Yup.object().shape({
  licensee_user_name: Yup.string().required('Licensee Name is required'),
  licensee_fee: Yup.string().required('Percentage is required'),
  publisher_user_name: Yup.string().required('Member Name is required'),
  publisher_fee: Yup.string().required('Percentage is required'),
  country_code: Yup.string().required('Territory is required'),
});

export const initialValues: RevenueShareDataType = {
  licensee_email: '',
  licensee_user_name: '',
  licensee_id: '',
  licensee_fee: 0,
  system_fee: 0,
  country_code: '',
  cmo_fee: 0,
  cmo_email: '',
  cmo_id: '',
  cmo_user_name: '',
  publisher_fee: 0,
  publisher_email: '',
  publisher_id: '',
  publisher_user_name: '',
  need_to_be_confirm: true,
};

export interface RevenueShareResponseType {
  _id: string;
  publisherId: {
    _id: string;
    userName: string;
    userEmail: string;
  };
  licenseeId: {
    _id: string;
    userName: string;
    userEmail: string;
  };
  cmoId: {
    _id: string;
    userName: string;
    userEmail: string;
  };
  publisherFee: number;
  licenseeFee: number;
  cmoFee: number;
  systemFee: number;
  appId: string;
  countryCode: string;
  needToBeConfirm: boolean;
  userConfirmed: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface autoCompleteUser {
  id: string;
  value: string;
  abel: string;
  userName: string;
  userEmail: string;
}
