export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_INFO = 'USER_INFO';
export const ResponseStatus = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
};
export const RoutePath = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PASS_PHRASE: '/passphrase',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_PASSWORD: '/forgot-password',
  CERTIFICATE_SONG: '/certificate-song',
  MEMBER_COUNTS_DETAIL_PENDING: '/member-counts-detail-pending/',
  SONG_COUNTS_DETAIL_PENDING: '/song-counts-detail-pending/',
  MEMBER_COUNTS_DETAIL_HISTORY: '/member-counts-history-detail/',
  SONG_COUNTS_DETAIL_HISTORY: '/song-counts-history-detail/',
  ACCRUAL_MEMBER_DETAIL: '/accrual-member-detail/',
  JOBS_POST: '/job-post/',
  JOBS_POST_VIEW_LOG: '/job-view-log/',
  PREVIEW_ACCOUNT_POSTING: '/preview-account-posting/',
};

export const DATE_FORMAT = 'YYYY-MM-DD';