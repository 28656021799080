import React from 'react';
import './App.css';
import { Login } from './pages/login';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import DefaultLayout from './layout/default-layout';
import { Profile } from './pages/profile';
import { Home } from './pages/home';
import { Navigate, useLocation } from 'react-router-dom';
import { ACCESS_TOKEN } from './constants/constant';
import {RoyaltyAccountPosting} from "./pages/royalty_account_posting";
import {MyLicensing} from "./pages/my_licensing";
import {MySongs} from "./pages/my_songs";
import {CertificateSong} from "./pages/certificate_song";
import {Logout} from "./pages/logout";
import { MemberCountsPending} from "./pages/member_counts_pending";
import {MemberCountsDetailPending} from "./pages/member_counts_detail_pending";
import {JobPost} from "./pages/job_posts";
import {JobPostViewLog} from "./pages/job_logs";
import {Settings} from "./pages/setting";
import {RevenueShare} from "./pages/revenue_share";
import {AccountingAccrual} from "./pages/accounting_accrual";
import {MemberCountsHistory} from "./pages/member_counts_history";
import {MemberCountsHistoryDetail} from "./pages/member_counts_history_detail";
import {PreviewRoyaltyAccountPosting} from "./pages/preview_royalty_account_posting";
import {Members} from "./pages/members";
import {SongCountsDetailPending} from "./pages/song_count_detail_pending";
import {SongCountsDetailHistory} from "./pages/song_count_detail_history";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<DefaultLayout />}>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/member-counts-pending" element={<MemberCountsPending />} />
      <Route path="/member-counts-detail-pending/:publisherId" element={<MemberCountsDetailPending />}  />
      <Route path="/song-counts-detail-pending" element={<SongCountsDetailPending />} />
      <Route path="/my-licensing" element={<MyLicensing />} />
      <Route path="/my-songs" element={<MySongs />} />
      <Route path="/job-post" element={<JobPost />} />
      <Route path="/accrual-member-detail" element={<AccountingAccrual />} />
      <Route path="/job-view-log/:jobId" element={<JobPostViewLog />} />
      <Route path="/certificate-song" element={<CertificateSong />} />
      <Route path="/royalty-account-posting" element={<RoyaltyAccountPosting />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/revenue-share" element={<RevenueShare />} />
      <Route path="/member-counts-history" element={<MemberCountsHistory />} />
      <Route path="/member-counts-history-detail" element={<MemberCountsHistoryDetail />}  />
      <Route path="/song-counts-history-detail" element={<SongCountsDetailHistory />}  />
      <Route path="/preview-account-posting/:jobId" element={<PreviewRoyaltyAccountPosting />}  />
      <Route path="/members" element={<Members />}  />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
    </Route>,
  ),
);

// @ts-ignore
function RequireAuth({ children }) {
  let location = useLocation();
  const authed = !!localStorage.getItem(ACCESS_TOKEN);
  return authed ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export const App = () => {
  return <RouterProvider router={router} />;
};
