import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {Formik, useFormik} from 'formik';
import type { MenuProps } from 'antd';
import { CustomDropdown } from '../../components/dropdown';
import {createOrUpdateLicensing} from "../../redux/licensing/licenseThunk";
import {initialValues, licenseSchema, LicensingDataType, menuCurrencyItems, menuRecurringItems} from "./license.type";
import { AutoComplete } from 'antd';
import {toast} from "react-toastify";
import clsx from "clsx";
import {getUserAutoComplete} from "../../redux/home/homeThunk";

const columns: ColumnsType<LicensingDataType> = [
  {
    title: 'License Account',
    dataIndex: 'license_account',
    key: 'license_account',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Debit Amount',
    dataIndex: 'debit_amount',
    key: 'debit_amount',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Currency Unit',
    dataIndex: 'currency_unit',
    key: 'currency_unit',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Recurring',
    key: 'recurring',
    dataIndex: 'recurring',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'CMO Fee',
    key: 'cmo_fee',
    dataIndex: 'cmo_fee',
    render: (text) => <span>{text}%</span>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <div className={'flex flex-row items-center'}>
        <button
        type="submit"
        className={
          'py-2 px-4 mr-3 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
        }
      >
        Edit
      </button>
        <button
            type="submit"
            className={
              'py-2.5 px-4 rounded-md bg-red-500 text-white'
            }
        >
          Delete
        </button>
      </div>
    ),
  },
];



export const MyLicensing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const handleSearch = (value: string) => {
    dispatch(getUserAutoComplete({keyword: value}))
      .unwrap()
      .then((response) => {
        setOptions(response.data.data)
      });
  };

  useEffect(() => {}, [dispatch]);

  const submitForm = (values: LicensingDataType, actions: any) => {
    console.log(formik.errors);
    console.log(formik.values);
    //validate cmo information if user added
    if(formik.values.cmo_user_name){
      if(!formik.values.cmo_id){
        formik.setErrors({cmo_user_name:"User not found"})
        return;
      }
      if(formik.values.cmo_fee <= 0){
        formik.setErrors({cmo_fee:"invalid cmo fee"})
        return;
      }
    }
    try {
      setLoading(true);
      dispatch(
        createOrUpdateLicensing({
          licenseeId: values.license_id,
          cmoId: values.cmo_id,
          percent: values.cmo_fee,
          currency_unit: values.currency_unit
        }),
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          setIsModalOpen(false);
          toast.info(response.data.message);
        })
    }catch (ex){
      console.log(ex)
    }finally {
      setLoading(false);
    }
  };

  const onClickCurrency: MenuProps['onClick'] = ({ key }) => {
    formik.setFieldValue("currency_unit", key);
  };

  const onClickRecurring: MenuProps['onClick'] = ({ key }) => {
    formik.setFieldValue("recurring", key === 'true');
  };

  const renderCurrencyLabelDropdown = () => {
    let result = menuCurrencyItems.find((item) => item.key === formik.values.currency_unit)
    if(!result) result = menuCurrencyItems[0];
    return result.label;
  };

  const renderRecurringLabelDropdown = () => {
    let result = menuRecurringItems.find((item) => item.key === (formik.values.recurring? 'true': 'false'));
    if(!result) result = menuRecurringItems[0];
    return result.label;
  };


  const formik = useFormik<LicensingDataType>({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: licenseSchema,
    onSubmit: submitForm,
  });

  const renderAddNewLicensingModal = () => {
    return (
      <Modal
        width={600}
        title="Add New Licensing"
        open={isModalOpen}
        onCancel={()=>{setIsModalOpen(false)}}
        footer={[
          <Button key="back" onClick={()=>{setIsModalOpen(loading? true: false)}}>
            Cancel
          </Button>,
          <Button
            key="link"
            href="#"
            type="primary"
            loading={loading}
            onClick={()=>{
              if(formik.errors > 0) {
                toast.error(JSON.stringify(formik.errors))
                return
              }
              formik.submitForm().then(r => console.log(r))}}
          >
            {loading? "Processing" :"Submit"}
          </Button>,
        ]}
      >
        <form>
          <div className="mt-5">
            <div>
              <label className="block mb-1 font-bold">
                License ID
              </label>
              <div className={'flex flex-row justify-between'}>
                <AutoComplete
                  id="license_user_name"
                  bordered={false}
                  style={{width: '55%', height: '', padding: 8}}
                  onSearch={handleSearch}
                  placeholder="User Name"
                  options={options}
                  autoFocus
                  value={formik.values.license_user_name}
                  onBlur={formik.handleBlur('license_user_name')}
                  onChange={
                    formik.handleChange('license_user_name')
                  }
                  onSelect={(item: any, option) =>{
                    console.log(item, option);
                    if (!option) {
                      return;
                    }
                    const user = options.find(item => item.value = option.value);
                    // @ts-ignore
                    const {id, userEmail, userName} = user;
                    formik.setFieldValue(
                      'license_user_name',
                      userName || '',
                    );
                    formik.setFieldValue(
                      'license_id',
                      id || '',
                    );
                    formik.setFieldValue(
                      'license_email',
                      userEmail || '',
                    );
                  }}
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.license_user_name? "border-red-400": "border-gray-200"
                  )}
                />
                <div>
                  <input
                    name="license_account"
                    id="license_account"
                    autoFocus
                    disabled={true}
                    placeholder="Email"
                    value={formik.values.license_email}
                    onBlur={formik.handleBlur('license_account')}
                    onChange={formik.handleChange('license_account')}
                    className={clsx(
                    "outline-none px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                      formik.errors.license_email? "border-red-400": "border-gray-200"
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={'flex flex-row items-center justify-between'}>
              <CustomDropdown
                items={menuCurrencyItems}
                label={'Currency Unit'}
                onClick={onClickCurrency}
                labelDropdown={renderCurrencyLabelDropdown()}
              />
              <div className={'mt-4'}>
                <label className="block font-bold">Debit Amount</label>
                <input
                  name="debit_amount"
                  id="debit_amount"
                  autoFocus
                  min={0}
                  placeholder="100"
                  value={formik.values.debit_amount}
                  onBlur={formik.handleBlur('debit_amount')}
                  onChange={formik.handleChange('debit_amount')}
                  className={clsx(
                    "outline-none px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.debit_amount? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <CustomDropdown
                items={menuRecurringItems}
                label={'Recurring'}
                onClick={onClickRecurring}
                labelDropdown={renderRecurringLabelDropdown()}
              />
            </div>

            <div className={'flex flex-row justify-between'}>
              <div style={{width:'50%', marginRight:5}}>
                <label className="block font-bold">CMO ID (optional)</label>
                <AutoComplete
                  id="cmo_user_name"
                  bordered={false}
                  style={{width: '100%', height: '', padding: 8}}
                  onSearch={handleSearch}
                  placeholder="CMO Name"
                  options={options}
                  autoFocus
                  value={formik.values.cmo_user_name}
                  onBlur={formik.handleBlur('cmo_user_name')}
                  onChange={
                    formik.handleChange('cmo_user_name')
                  }
                  onSelect={(item: any, option) =>{
                    console.log(item, option);
                    if (!option) {
                      return;
                    }
                    const user = options.find(item => item.value = option.value);
                    // @ts-ignore
                    const {id, userEmail, userName} = user;
                    formik.setFieldValue(
                      'cmo_user_name',
                      userName || '',
                    );
                    formik.setFieldValue(
                      'cmo_id',
                      id || '',
                    );
                    formik.setFieldValue(
                      'cmo_email',
                      userEmail || '',
                    );
                  }}
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.license_user_name? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'50%',marginRight:5, marginTop:2}}>
                <input
                  name="cmo_email"
                  id="cmo_email"
                  autoFocus
                  disabled={true}
                  placeholder="email"
                  value={formik.values.cmo_email}
                  onBlur={formik.handleBlur('username')}
                  onChange={formik.handleChange('username')}
                  className={clsx(
                    "outline-none w-full mt-5 px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.cmo_user_name? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'20%'}}>
                <label className="block font-bold">Percentage(%)</label>
                <input
                  name="cmo_fee"
                  id="cmo_fee"
                  autoFocus
                  placeholder="10"
                  value={formik.values.cmo_fee}
                  onBlur={formik.handleBlur('cmo_fee')}
                  onChange={formik.handleChange('cmo_fee')}
                  className={clsx(
                    "outline-none w-full px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.cmo_fee? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
            </div>
          </div>
          </form>
      </Modal>
    );
  };

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 ml-10">
          <button
            type="submit"
            onClick={() => setIsModalOpen(true)}
            className={'p-2 rounded-md border-2 border-blue-600 text-blue-800'}
          >
            New Licensing
          </button>
        </div>
      </div>
      <Table columns={columns} dataSource={data} />
      {renderAddNewLicensingModal()}
    </div>
  );
};
