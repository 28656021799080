import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {
  GET_JOBS_POST,
  GET_JOBS_POST_LOG, GET_JOBS_POST_LOG_REALTIME,
  GET_MEMBER_COUNT_HISTORY,
  GET_MEMBER_COUNT_DETAIL_HISTORY,
  POST_ACTION,
  RECORD_PLAY_COUNT, GET_ACCRUAL_REPORT, GET_SONG_COUNT_DETAIL_PENDING, GET_SONG_COUNT_DETAIL_HISTORY
} from "../types";

export const getMemberCountHistory = createAsyncThunk(
  types.GET_MEMBER_COUNT_HISTORY,
  async (request: {startDate: string, endDate: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_COUNT_HISTORY, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getMemberCountDetailHistory = createAsyncThunk(
  types.GET_MEMBER_COUNT_DETAIL_HISTORY,
  async (request: {memberCountId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_COUNT_DETAIL_HISTORY, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getSongCountDetailHistory = createAsyncThunk(
  types.GET_SONG_COUNT_DETAIL_HISTORY,
  async (request: {memberCountDetailId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_SONG_COUNT_DETAIL_HISTORY, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const postingAction = createAsyncThunk(
  types.POST_ACTION,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.POST_ACTION)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getJobsPost = createAsyncThunk(
  types.GET_JOBS_POST,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_JOBS_POST)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getJobsPostDetail = createAsyncThunk(
  types.GET_JOBS_POST_LOG,
  async (request:{jobId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_JOBS_POST_LOG, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getPostLogRealtime = createAsyncThunk(
  types.GET_JOBS_POST_LOG_REALTIME,
  async (request:{jobId: string}) => {
    return axiosClient
      .post(API.GET_JOBS_POST_LOG_REALTIME, request)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
);

export const confirmJobData = createAsyncThunk(
  types.CONFIRM_JOB_POST,
  async (request:{jobId: string}) => {
    return axiosClient
      .get(API.CONFIRM_JOBS_POST + request.jobId)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
);

export const rerunJobPost = createAsyncThunk(
  types.RE_RUN_JOB_POST,
  async (request:{jobId: string}) => {
    return axiosClient
      .get(API.RE_RUN_JOB_POST + request.jobId)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  },
);


export const getMemberCountPending = createAsyncThunk(
  types.GET_MEMBER_COUNT_PENDING,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_COUNT_PENDING, )
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getMemberCountDetailPending = createAsyncThunk(
  types.GET_MEMBER_COUNT_DETAIL_PENDING,
  async (request: {publisherId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_COUNT_DETAIL_PENDING, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);
export const getSongCountDetailPending = createAsyncThunk(
  types.GET_SONG_COUNT_DETAIL_PENDING,
  async (request: {playTimeId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_SONG_COUNT_DETAIL_PENDING, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getAccuralReport = createAsyncThunk(
  types.GET_ACCRUAL_REPORT,
  async (request: {startDate: string, endDate: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_ACCRUAL_REPORT, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getAccuralMemberReport = createAsyncThunk(
  types.GET_ACCRUAL_MEMBER_REPORT,
  async (request: {publisherId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_ACCRUAL_MEMBER_REPORT, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);



