import React from 'react';
import { useAppSelector } from '../../redux/store';
import { Space, Spin } from 'antd';

export const LoadingComponent = () => {
  const isLoading = useAppSelector((state) => state.app.loading);
  return isLoading ? (
    <Space
      className={
        'absolute w-full h-full top-0 bottom-0 z-40 align-middle justify-center'
      }
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <Spin size="large" />
    </Space>
  ) : null;
};
