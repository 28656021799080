import * as Yup from 'yup';
import {MenuItemType} from "antd/es/menu/hooks/useItems";
export interface JobPostType {
  _id: string;
  jobStatus: string;
  jobIsFinished: boolean;
  jobCurrentStep: number;
  members: [];
  playCountData: [];
  payOutData: any;
  percentComplete: number;
  createdAt: Date;
  updatedAt: Date;
};

export const JobPostStepType = {
    STEP_1: 0,
    STEP_2: 1,
    STEP_3: 2, //confirm step
    STEP_4: 3, //confirmed step
    STEP_5: 4, // staging payout
    STEP_6: 5, // completed payout
};