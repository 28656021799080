import React, {useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import {Formik, useField} from 'formik';
import clsx from "clsx";
import {initialValues, settingSchema, SettingType} from "./setting.type";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../redux/store";
import {getSetting, saveSetting} from "../../redux/setting/settingThunk";

// @ts-ignore
const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  // @ts-ignore
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-area" style={{ width: '100%' }} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
export const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const [configData, setConfigData] = useState('');
  const onSubmit = (values: SettingType) => {
    try {
      const config = JSON.parse(values.config);
      dispatch(saveSetting({config: JSON.stringify(config)}))
        .unwrap()
        .then((response) => {
          toast.info("Saved")
        });
    }catch (error){
      toast.error('Error parsing')
    }
  }

  const getSettingData = () => {
    dispatch(getSetting())
      .unwrap()
      .then((response) => {
        setConfigData(response.data.data);
      });
  };

  useEffect(() => {
    void getSettingData();
  }, [dispatch]);

  return (
    <div className="w-full overflow-hidden">
      <div className="md:w-1/3 mx-auto">
      <span className="uppercase text-2xl font-semibold mx-4">
        Setting
      </span>
      <div className="mx-auto p-10">
        <Formik
          // @ts-ignore
          initialValues={{config: configData}}
          validationSchema={settingSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}>
          {({
              handleSubmit,
              isSubmitting,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
            }) => {
            return (
              <div className="mt-14">
                <div>
                  <MyTextArea
                    label=""
                    name="config"
                    rows="10"
                    columns="10"
                    value={values.config}
                    placeholder="{
                      goverment_tax':
                        [{
                          'contryCode': 'sg',
                          'value': '10%'
                        }]
                    }"
                  />
                </div>
                <div className="mt-8">
                  <button
                    type="submit"
                    // disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                    className={clsx({
                      'px-4 py-3 rounded-md text-gray-50 w-full': true,
                      'bg-gray-400': isSubmitting,
                      'bg-blue-700': !isSubmitting,
                    })}
                  >
                    Save
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
        </div>
      </div>
    </div>
  );
};
