import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

export const CertificateSong: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {}, [dispatch]);

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
        <div className={'flex flex-row'}>
            <div className={'flex flex-col'}>
                <span className={'font-bold text-xl'}>Certificate of Registration</span>
                <span>Date: </span>
                <span>Owner Name: </span>
                <span>Phone: </span>
                <span>Title: </span>
                <span>Singer: </span>
                <span>Copyrights Owner: </span>
                <span>Duration: </span><br/>
            </div>
            <div className={'flex flex-col  ml-8'}>
                <span className={'font-bold'}>Registration Address</span>
            <img
                src="../../assets/icons/logo_home.png"
                className="w-40 h-40"
             />
            </div>
        </div><br/>
        <span className={'font-bold text-xl'}>Provenance / Licensing history</span>
        <span>Song Address: </span>
        <span>Hash: </span>
        <span>Owner address: </span>
        <span>Created at: </span><br/><br/>
        <span className={'font-bold text-xl'}>Royalty Distribution</span>
        <span>Royalty Split Application ID: </span>
        <span>Partner Name: </span>
        <span>Partner Address: </span>
        <span>Percent: </span>
        <span className={'font-bold text-xl'}>Digital Signature</span>
    </div>
  );
};
