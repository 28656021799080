import * as Yup from 'yup';
import {MenuItemType} from "antd/es/menu/hooks/useItems";

export interface LicensingDataType {
  key?: number;
  license_email: string;
  license_user_name: string;
  license_id: string;
  currency_unit: string;
  debit_amount: number;
  cmo_fee: number;
  cmo_email: string;
  cmo_id: string;
  cmo_user_name: string;
  recurring: boolean;
}
export const licenseSchema = Yup.object().shape({
  license_user_name: Yup.string().required('License Name is required'),
  currency_unit: Yup.string().required('Currency Unit is required'),
  recurring: Yup.string().required('Recurring Unit is required'),
  // debit_amount: Yup.string().required('Debit amount is required'),
});

export const initialValues: LicensingDataType = {
  license_email: '',
  license_user_name: '',
  license_id: '',
  currency_unit: 'sg',
  debit_amount: 0,
  cmo_fee: 0,
  cmo_email: '',
  cmo_id: '',
  cmo_user_name: '',
  recurring: false,
};

export const menuCurrencyItems: MenuItemType[] = [
  {
    label: 'SGD',
    key: 'sgd',
  },
  {
    label: 'USD',
    key: 'usd',
  },
];

export const menuRecurringItems: MenuItemType[] = [
  {
    label: 'True',
    key: 'true',
  },
  {
    label: 'False',
    key: 'false',
  },
];
