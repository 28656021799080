import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {
  RESET_REPORT_DATA
} from "../types";
export const resetReportDataForTestingPurpose = createAsyncThunk(
  types.RESET_REPORT_DATA,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.RESET_REPORT_DATA)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);



