import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {DatePicker, Space, Table, Tag} from 'antd';
import {ColumnsType} from "antd/es/table";
import {AccrualType} from "./accrual.type";
import {getAccuralMemberReport} from "../../redux/report/reportThunk";

const columns: ColumnsType<AccrualType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Transaction type',
    dataIndex: 'transactionType',
    key: 'transactionType',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Transaction ID',
    dataIndex: 'txId',
    key: 'txId',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Credits',
    dataIndex: 'credits',
    key: 'credits',
    render: (_, record) => (
      <span>{record.credits > 0? (record.credits/1000).toFixed(2) :''}</span>
    ),
  },
  {
    title: 'Debits',
    dataIndex: 'debits',
    key: 'debits',
    render: (_, record) => (
      <span>{record.debits > 0?  (record.debits/1000).toFixed(2) :''}</span>
    ),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, record) => (
      <span>{ (record.balance >0 && record.txId)? (record.balance/1000).toFixed(2) : 0}</span>
    ),
  },
]


export const AccountingAccrual: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [accrualData, setAccrualData] = useState([]);
  const location = useLocation().state;
  if(!location){
    navigate('/')
  }
  let { publisher } = location;

  const getAccrualReportData = () => {
    dispatch(getAccuralMemberReport({publisherId: publisher.userRefer._id}))
      .unwrap()
      .then((response) => {
        setAccrualData(response.data.data);
      });
  };
  useEffect(() => {
    void getAccrualReportData();
  }, [publisher]);

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 hidden">
          <DatePicker picker='date' onChange={(value)=> console.log(value)} />
          <DatePicker className={'ml-5'} picker='date' onChange={(value)=> console.log(value)} />
          <button
            type="submit"
            // onClick={() => downloadKeystore()}
            className={
              'p-2 rounded-md border-2 border-blue-600 text-blue-800'
            }
          >
            Get Report
          </button>
        </div>
      </div>
      <label className={'self-center font-bold text-2xl text-blue-800 mb-10'}>STATEMENT OF ROYALTY ACCRUAL</label>
      <div className={'flex flex-col mb-5 text-blue-800'}>
        <label>Account Name: {publisher.userRefer.userName}</label>
        <label>Account No: {publisher.userRefer.userPublicAddress}</label>
      </div>
      <Table columns={columns} dataSource={accrualData} />
    </div>
  );
};
